/* Основные стили для таблицы */
.big-table {
	overflow: auto;
	position: relative;
	background-color: rgb(29, 28, 39); /* Темный фон для консистентности */
	border-radius: 8px;
	padding: 10px;
}

.big-table table {
	width: 100%;
	border-collapse: collapse;
	overflow-x: auto;
}

/* Заголовок таблицы */
.table th {
	font-weight: bold;
	padding: 10px;
	background-color: rgb(44, 44, 56); /* Цвет схож с цветом строк */
	color: white;
	border: 1px solid #F2F8F8; /* Границы такие же, как у тела таблицы */
	cursor: pointer;
	position: relative;
	white-space: nowrap;
}

.table th:hover {
	background-color: rgb(67, 67, 82); /* Легкое выделение при наведении */
}

/* Иконка сортировки */
.table th .sort-icon {
  margin-left: 8px;
  font-size: 18px; /* Увеличиваем размер значка сортировки */
  color: #f0f0f0; /* Светлый цвет для значка */
  vertical-align: middle; /* Выравнивание по центру с текстом */
}

.table th.sorted-asc .sort-icon {
  content: '▲'; /* Значок для сортировки по возрастанию */
}

.table th.sorted-desc .sort-icon {
  content: '▼'; /* Значок для сортировки по убыванию */
}

/* Тело таблицы */
.table td {
	padding: 10px;
	border: 1px solid #F2F8F8;
	color: white;
}

/* Чередование строк */
tr:nth-child(even) {
	background-color: rgb(44, 44, 56); /* Чередование строк */
}

tr:hover {
	background-color: rgb(67, 67, 82); /* Подсветка строки при наведении */
}

/* Кнопки редактирования и удаления записей внутри таблицы */
.table-button {
	background-color: rgba(255, 255, 255, 0.2); /* Немного менее прозрачные */
	color: white;
	border: none;
	padding: 5px 10px;
	cursor: pointer;
	border-radius: 4px;
	transition: background-color 0.3s ease;
	font-size: 20px; /* Увеличиваем размер значков */
}

.table-button:hover {
	background-color: rgba(255, 255, 255, 0.4); /* Больше выделяются при наведении */
}

/* Стили для header */
header {
	display: flex;
	justify-content: space-between; /* Разнесение элементов по сторонам */
	align-items: center;
	background-color: rgb(52, 106, 207); /* Синий цвет как в заголовке формы */
	padding: 10px 20px;
	color: white;
	border-radius: 8px;
	margin-bottom: 20px; /* Добавляем отступ снизу для отделения от фильтров */
}

/* Ссылка на главную слева */
header a {
	color: white;
	font-size: 18px;
	text-decoration: none;
	font-weight: bold;
}

header a:hover {
	text-decoration: underline;
}

/* Блок поиска в центре */
header .search-container {
	flex: 1;
	display: flex;
	justify-content: center;
}

header .search-container input {
	width: 300px;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	background-color: rgb(29, 28, 39);
	color: white;
}

/* Блок кнопок справа */
header .button-group {
	display: flex;
	gap: 10px;
}

/* Стили для кнопок "Добавить" и "Удалить" */
.header-button {
	background: transparent;
	border: 1px solid white;
	color: white;
	cursor: pointer;
	padding: 10px 20px;
	border-radius: 4px;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.header-button:hover {
	background-color: white;
	color: rgb(52, 106, 207); /* Меняем текст на синий при наведении */
}
