/* Форма отображается поверх страницы */
.form-container {
  position: fixed; /* Фиксированное позиционирование */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; /* Увеличиваем z-index, чтобы форма была поверх всех элементов */
  background-color: rgba(0, 0, 0, 0.5); /* Затемняем задний фон */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.scrollable-form {
  max-height: 80vh;
  overflow-y: auto;
  padding: 0px;
  background-color: rgb(0, 0, 0);
}
  /* Стиль для input */
.form-field input {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  box-sizing: border-box;
  font-size: 16px;
}
form{
  background-color: rgb(29, 28, 39);
  padding: 20px;
}
/*Стили для шапки*/
.form-header {
  position: sticky;
  top: 0;
  background: rgb(52, 106, 207);
  z-index: 10;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex; 
  justify-content: space-between; 
}
/*кнопка закрытия в шапке*/
.close-button-header {
  background: transparent;
  border: none;
  color: black;
  cursor: pointer;
  position: relative; 
  width: 40px; /* Ширина кнопки */
  height: 40px; /* Высота кнопки */
}
  
.close-button-header::before,
.close-button-header::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px; /* Ширина линий креста */
  height: 3px;
  /* Высота линий креста */
  background-color: rgb(0, 0, 0); /* Цвет линий */
}
  
.close-button-header::before {
  transform: translate(-50%, -50%) rotate(45deg); /* Поворот первой линии */
}
  
.close-button-header::after {
  transform: translate(-50%, -50%) rotate(-45deg); /* Поворот второй линии */
}
/*кнопка сохранения/добавления в шапке*/
.checkmark-button {
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  width: 40px; /* Ширина кнопки */
  height: 40px; /* Высота кнопки */
}

.checkmark-button::before,
.checkmark-button::after {
  content: '';
  position: absolute;
  background: rgb(0, 0, 0); /* Цвет галочки */
}

.checkmark-button::before {
  width: 3px; /* Толщина линии */
  height: 23px; /* Высота линии */
  left: 30px; /* Положение линии */
  bottom: 9px;
  transform: rotate(45deg);
}
.checkmark-button::after {
  width: 3px; 
  height: 13px;
  left: 19px; 
  bottom: 11px;
  transform: rotate(-45deg);
}
/* Стиль для ошибки */
.error-message {
  color: red;
  font-size: 12px;
}

/* Пример для улучшения видимости значений */
.form-field label {
  font-weight: bold;
}
/*стили для кнопок*/
.buttons-container {
  display: flex; 
  justify-content: center; /* Центрирует кнопки по горизонтали */
  align-items: center; /* Выравнивает кнопки по вертикали */
  gap: 16px; 
  margin-top: 20px; 
}
/* Все кнопки одного размера с ограниченной шириной */
.buttons-container button {
  max-width: 155px; 
  padding: 10px; 
  flex: 1;
}
/* Эффект для кнопки закрыть */
.buttons-container .close-button{
  color: rgb(186, 193, 211);
}
.buttons-container .close-button:hover {
  background-color: rgba(255, 255, 255, 0.842); /* Меняет фон на серый при наведении */
  color: rgb(0, 0, 0);
}
