.text {
	text-align: center;
	color: white; /* Белый текст */
	padding: 50px;
	background-color: rgb(29, 28, 39); /* Фон, похожий на форму редактирования */
	border-radius: 8px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4); /* Добавляем тень для выделения */
}

.nav-links {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-top: 20px;
}

.nav-links a {
	color: rgb(52, 106, 207); /* Используем цвет заголовков из таблицы */
	background-color: rgb(29, 28, 39); /* Фон ссылки */
	padding: 10px 20px;
	border-radius: 4px;
	text-decoration: none;
	transition: background-color 0.3s ease;
}

.nav-links a:hover {
	background-color: rgb(52, 106, 207); /* Меняем фон при наведении */
	color: white; /* Белый текст при наведении */
}
.content{
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

ul{
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

}

/* Стили для элемента списка */
ul li{
	display: flex;
    align-items: center;
    margin: 10px 0;
}