.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0;
	background-color: rgb(29, 28, 39); /* Темный фон */
	border-radius: 8px;
	padding: 10px;
}

.pagination-button {
	background-color: rgb(52, 106, 207); /* Цвет кнопок */
	color: white;
	border: none;
	padding: 10px 20px;
	margin: 0 5px;
	cursor: pointer;
	border-radius: 5px;
	transition: background-color 0.3s ease;
}

.pagination-button:disabled {
	background-color: #d3d3d3;
	cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
	background-color: rgba(52, 106, 207, 0.8);
}

.pagination-info {
	margin: 0 10px;
	font-size: 16px;
	color: white; /* Белый текст */
}
